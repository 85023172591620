import React, { useEffect, useState } from "react";
import "./portfolio-section.scss";
import { Col, Container, Row } from "codeebo-react-grid";
import { PortfolioData, PortfolioItemDto } from "../../../../_data/PortfolioData";
import ScrollWrapper from "../../../../../tools/scroll-wrapper/scroll-wrapper";
import { handleScrollToSectionId } from "../../../../../tools/handleScrollToSection";
import { RoutePath } from "../../../../../route-paths";
import TopLink from "../../../../../libs/top-link/top-link";
import useWindowSize from "../../../../../tools/useWindowSize";

const PortfolioMenu = () => {
  const [activeSection, setActiveSection] = useState("");

  const isElementVisible = (id: string, offset: number): boolean => {
    const element = document.getElementById(id);

    if (!element) {
      return false;
    }

    const { top } = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    return top - offset <= windowHeight;
  };
  const calculateHalfScreenHeight = (): number => {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    return Math.floor(windowHeight / 2);
  };
  const offset = 0;

  const scrollHandler = () => {
    const sections = document.querySelectorAll<HTMLElement>('[id^="portfolio-item-"]');
    sections.forEach((section) => {
      const isVisible = isElementVisible(section.id, -calculateHalfScreenHeight() + offset);
      if (isVisible) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="portfolio-menu-wrapper" data-aos="fade-in">
      <div className="portfolio-menu">
        {PortfolioData.map((x, index: number) => {
          return (
            <div
              className={`portfolio-menu-item my-2 ${activeSection === "portfolio-item-" + x.id ? "active" : ""}`}
              onClick={() => handleScrollToSectionId("portfolio-item-" + x.id)}
              key={index}
            >
              {x.title}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const PortfolioTile = ({ data, offset, opacity = 1 }: { data: PortfolioItemDto; offset: number; opacity: number }) => {
  const { width } = useWindowSize();
  const stickyInityStop = 40;
  const tileOffsetToRight = width > 1200 ? 16 : 0;
  const tileOffsetTop = 16;

  return (
    <>
      <div className="portfolio-tile-helper" id={"portfolio-item-" + data.id}></div>
      <div
        className="portfolio-tile"
        style={{
          transform: `translateY(${stickyInityStop + tileOffsetTop * offset}px)`,
          marginLeft: `${tileOffsetToRight * offset}px`,
          opacity: opacity,
        }}
      >
        {data.disabled ? (
          <span className={data.disabled ? "disabled" : ""}>
            <div
              className="portfolio-tile-image"
              style={{
                opacity: opacity,
                backgroundImage: `url("${data.cover}")`,
              }}
            />
          </span>
        ) : (
          <TopLink to={RoutePath.PORTFOLIO.generator(data.id)} className={data.disabled ? "disabled" : ""}>
            <div
              className="portfolio-tile-image"
              style={{
                opacity: opacity,
                backgroundImage: `url("${data.cover}")`,
              }}
            />
          </TopLink>
        )}
      </div>
    </>
  );
};

const PortfolioSection: React.FC<any> = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const totalTiles = PortfolioData.length;

  const calcOpacity = (progress: number, index: number): number => {
    const offsetIndex = index + 0.9;
    const tileScrollStart = (100 / totalTiles) * offsetIndex;
    const tileScrollEnd = (100 / totalTiles) * (offsetIndex + 1);
    const tileScrollStep = tileScrollEnd - tileScrollStart;
    let tileScrollProgress = 0;
    if (scrollProgress <= tileScrollStart) tileScrollProgress = 0;
    else if (scrollProgress >= tileScrollEnd) tileScrollProgress = 1;
    else {
      tileScrollProgress = (scrollProgress - tileScrollStart) / tileScrollStep;
    }

    const opacity = Math.max(1 - tileScrollProgress, 0.2);
    return opacity;
  };

  return (
    <section className="portfolio-section my-md-5 my-4 py-md-5 py-4" id="projects">
      <Container>
        <ScrollWrapper onScrollProgress={(progress) => setScrollProgress(progress)} scrollReference="center">
          <Row>
            <Col size={[2, 2, 2, 12]} className="pt-4 pb-5 mb-5 hide-on-mobile">
              <PortfolioMenu />
            </Col>
            <Col size={[10, 9, 9, 12]} className="portfolio-col">
              <h2 className="sectiontitle show-on-mobile" data-aos="fade-in">
                Portfolio
              </h2>
              {PortfolioData.map((item: PortfolioItemDto, index: number) => {
                return (
                  <PortfolioTile data={item} key={index} offset={index} opacity={Math.max(calcOpacity(scrollProgress, index), (index + 1) / totalTiles)} />
                );
              })}
            </Col>
          </Row>
        </ScrollWrapper>
      </Container>
    </section>
  );
};

export default PortfolioSection;

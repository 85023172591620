import "./hero-section.scss";
import { Col, Container, Row } from "codeebo-react-grid";
import { PortfolioItemDto } from "../../../../_data/PortfolioData";
import TopLink from "../../../../../libs/top-link/top-link";

const HeroSection = ({ portfolioItem }: { portfolioItem: PortfolioItemDto }) => {
  return (
    <section className="hero-section py-md-5 py-4" data-aos="fade-in">
      <Container className="pt-5">
        <Row>
          <Col size={12}>
            <TopLink to="/">
              <img className="p-1 back-icon" src="/images/system-icons/left-arrow-icon.svg" alt="left arrow" />
            </TopLink>
            <div className="pretitle pb-3 pt-4">{portfolioItem.pretitle}</div>
            <h1>{portfolioItem.title}</h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;

export const handleScrollToSection = (sectionTarget: React.MutableRefObject<any> | string, navOffset?: number, isSmooth?: boolean) => {
  let scrollTarget;
  if (typeof sectionTarget === "string") {
    scrollTarget = document.getElementById(sectionTarget);
  } else {
    scrollTarget = sectionTarget.current;
  }

  if (scrollTarget === undefined) return;

  if (isSmooth) {
    let options = {
      left: 0,
      top: scrollTarget.getBoundingClientRect().top + window.pageYOffset - (navOffset || navOffset === 0 ? navOffset : 65),
      behavior: "smooth",
    } as ScrollToOptions;
    window.scrollTo(options);
  } else {
    window.scrollTo(0, scrollTarget.getBoundingClientRect().top + window.pageYOffset - (navOffset || navOffset === 0 ? navOffset : 65));
  }
};

export const handleScrollToSectionId = (sectionId: string) => {
  if (sectionId) {
    const scrollTarget = document.getElementById(sectionId);
    window.scrollTo({
      top: (scrollTarget && scrollTarget.offsetTop ? scrollTarget!.offsetTop : 0) - 8,
      behavior: "smooth",
    });
  }
};

import useTheme from "../../../tools/theme-controller/useTheme";
import "./theme-switch.scss";

function ThemeSwitch() {
  const { theme, setTheme } = useTheme();
  return (
    <>
      <div className={`theme-switch`} onClick={() => setTheme(theme === "dark" ? "light" : "dark")}>
        <div className="image-box">
          <img id="dark-icon" src="/images/system-icons/dark.svg" alt="change theme" className={theme === "dark" ? "hidden" : ""} />
          <img id="light-icon" src="/images/system-icons/light.svg" alt="change theme" className={theme === "light" ? "hidden" : ""} />
        </div>
      </div>
    </>
  );
}

export default ThemeSwitch;

import { useEffect } from "react";
import { useAppSelector } from "../../redux/store";
import themeSlice, { selectTheme } from "./themeSlice";
import { useDispatch } from "react-redux";

export default function useTheme() {
  const theme = useAppSelector(selectTheme);
  const dispatch = useDispatch();
  const setTheme = (theme: "dark" | "light" | undefined) => dispatch<any>(themeSlice.actions.setTheme(theme));

  useEffect(() => {
    var html = document.getElementsByTagName("html")[0];

    if (theme === "dark") html.classList.add("theme-dark");
    else html.classList.remove("theme-dark");
  }, [theme]);

  return { theme, setTheme };
}

import "./navbar.scss";
import { useState, useEffect } from "react";
import Button from "../../../libs/buttons/Button/Button";
import { Col, Container, Row } from "codeebo-react-grid";
import { RoutePath } from "../../../route-paths";
import { useLocation, useNavigate } from "react-router-dom";
import { handleScrollToSectionId } from "../../../tools/handleScrollToSection";
import { useTrigger } from "../../../libs/GlobalTrigger/GlobalTriggerSlice";
import TopLink from "../../../libs/top-link/top-link";
import ThemeSwitch from "../theme-switch/theme-switch";
import useTheme from "../../../tools/theme-controller/useTheme";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const { hash, pathname } = useLocation();
  const { triggerId, setTrigger } = useTrigger();
  const navigate = useNavigate();
  useEffect(() => {
    if (hash) {
      handleScrollToSectionId(hash.replace("#", ""));
      window.history.replaceState("", document.title, window.location.pathname);
    }
  }, [hash, pathname]);

  const handleClickNavLink = (e: any, id: string) => {
    e.preventDefault();
    window.history.replaceState("", document.title, window.location.pathname);
    if (pathname === RoutePath.HOME) handleScrollToSectionId(id);
  };

  useEffect(() => {
    if (triggerId === "scroll-home") {
      if (window.location.hash) {
        window.history.replaceState("", document.title, window.location.pathname);
      }
      handleScrollToSectionId("app");
      if (pathname !== RoutePath.HOME) navigate(RoutePath.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerId]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const links = (isHome: boolean) => {
    return (
      <>
        <li className="menu-item">
          <ThemeSwitch />
        </li>
        {isHome ? (
          <>
            <li className="menu-item hide-on-mobile">
              <a onClick={(e) => handleClickNavLink(e, "projects")} href={RoutePath.HOME + "#projects"}>
                Projects
              </a>
            </li>
            <li className="menu-item hide-on-mobile">
              <a onClick={(e) => handleClickNavLink(e, "approach")} href={RoutePath.HOME + "#approach"}>
                Approach
              </a>
            </li>
            <li className="menu-item hide-on-mobile">
              <a onClick={(e) => handleClickNavLink(e, "process")} href={RoutePath.HOME + "#process"}>
                Process
              </a>
            </li>
          </>
        ) : null}
        <a href="https://read.cv/sp_nm" target="_blank" rel="noreferrer">
          <Button className="read-cv">Read.cv</Button>
        </a>
      </>
    );
  };
  const { theme } = useTheme();

  return (
    <nav className={`navbar ${isMobileMenuOpen ? "mobile-menu-open" : ""}`}>
      <Container>
        <Row align="center">
          <Col size={[4, 4, 4, 4]}>
            <TopLink to={RoutePath.HOME}>
              <div className="logo" onClick={(e) => setTrigger("scroll-home")}>
                {theme === "light" ? <img src="/images/logo-dark.svg" alt="Logo" /> : <img src="/images/logo.svg" alt="Logo" />}
              </div>
            </TopLink>
          </Col>
          <Col size={[8, 8, 8, 8]}>
            <ul className="menu">{links(pathname === RoutePath.HOME)}</ul>

            {/* {isMobileView ? (
              <HamburgerButton onClick={toggleMobileMenu} ariaExpanded={isMobileMenuOpen} />
            ) : (
              <ul className="menu">{links(pathname === RoutePath.HOME)}</ul>
            )} */}
          </Col>
          {isMobileMenuOpen && isMobileView && (
            <Col size={[12, 12, 12, 12]}>
              <ul className="menu mobile-menu">{links(pathname === RoutePath.HOME)}</ul>
            </Col>
          )}
        </Row>
      </Container>
    </nav>
  );
};

export default Navbar;

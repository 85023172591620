import React from "react";
import "./hello-section.scss";
import { Col, Container, Row } from "codeebo-react-grid";

const HelloSection: React.FC<any> = () => {
  return (
    <section className="hello-section my-md-5 my-4 py-md-5 py-4">
      <Container>
        <Row>
          <Col size={12}>
            <h2 data-aos="fade-in">
              Oh hello, I&apos;m sorry, I didn&apos;t see you there. <br />
              My name is Simon, I&apos;m a graphic designer <br />
              and this is my <span className="badge">UI&UX</span> focused website.
            </h2>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HelloSection;

import "./gallery-section.scss";
import { Col, Container, Row } from "codeebo-react-grid";
import { PortfolioItemDto } from "../../../../_data/PortfolioData";

const GallerySection = ({ portfolioItem }: { portfolioItem: PortfolioItemDto }) => {
  return (
    <section className="gallery-section">
      <Container className="py-5">
        <Row>
          <Col size={12}>
            {portfolioItem.images.map((x: string, index: number) => {
              return (
                <div className="pb-md-5 pb-4 mb-md-5 mb-3" key={index}>
                  <img src={x} alt={portfolioItem.title + " " + index} data-aos="fade-up" />
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GallerySection;

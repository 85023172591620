import React from "react";
import "./hero-section.scss";
import { Col, Container, Row } from "codeebo-react-grid";
// import * as TypeWriter from "react-typewriter";
import Typewriter from "typewriter-effect";

const HeroSection: React.FC<any> = () => {
  return (
    <section className="hero-section my-md-4 my-3 py-md-5 py-4">
      <Container className="py-5 mb-5">
        <Row>
          <Col size={11}>
            <h1>
              {/* <span>Simple UI,</span> <br />
              <span>heuristic UX,</span> <br />
              <span>limited BS</span> */}

              {/* <TypeWriter typing={1}>
                <span>Simple UI,</span> <br />
                <span>heuristic UX,</span> <br />
                <span>limited BS</span>
              </TypeWriter> */}

              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    // .changeDelay(60)
                    // .changeDeleteSpeed(160)
                    .typeString(`<span>Simple user inte`)
                    .changeDelay(1000)
                    .deleteChars(9)
                    .changeDelay(100)
                    .typeString(`UI,</span><br><span>heuristic UX,</span><br><span>limited BS</span>`
                    )
                    .start();
                }}
              />
              {/* 
              <TypeAnimation sequence={["Simple UI,"]} wrapper="span" speed={50} />  <br />
              <TypeAnimation sequence={["heuristic UX,"]} wrapper="span" speed={50} />  <br />
              <TypeAnimation sequence={["limited BS"]} wrapper="span" speed={50} />  */}
            </h1>
          </Col>
          <Col size={1}>
            <h1>
              &nbsp;
              <br />
              &nbsp;
              <br />
              &nbsp;
            </h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;

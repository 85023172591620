import React from "react";
import "./home-page.scss";

import { Helmet } from "react-helmet-async";

import HeroSection from "./sections/hero-section/hero-section";
import HelloSection from "./sections/hello-section/hello-section";
import PortfolioSection from "./sections/portfolio-section/portfolio-section";
import IfyouareSection from "./sections/ifyouare/ifyouare-section";
import ApproachSection from "./sections/approach/approach-section";
import ProcessSection from "./sections/process-section/process-section";
import ToolstackSection from "./sections/toolstack-section/toolstack-section";
import QuestionsSection from "./sections/questions/questions-section";

const HomePage: React.FC<any> = () => {
  return (
    <div className="home-page">
      <Helmet>
        <title>{"Stuxpid"}</title>
      </Helmet>
      <HeroSection />
      <HelloSection />
      <PortfolioSection />
      <IfyouareSection />
      <ApproachSection />
      <ProcessSection />
      <ToolstackSection />
      <QuestionsSection />
    </div>
  );
};

export default HomePage;

import Button from "../../../../../../libs/buttons/Button/Button";
import TextBlock from "../../../../../../libs/text-block/text-block";

interface Props {
  primaryColor: string;
  fontsColor: string;
  buttonTitle: string;
  children: React.ReactNode;
}

const StandardDescriptionBlock = (props: Props) => {
  const { primaryColor, fontsColor, children, buttonTitle } = props;
  return (
    <>
      <TextBlock className="pb-4">{children}</TextBlock>
      <a href="mailto:contact@stuxpid.com">
        <Button style={{ background: primaryColor, borderColor: primaryColor, color: fontsColor === "light" ? "#ffffff" : "#000000" }}>{buttonTitle}</Button>
      </a>
    </>
  );
};

export default StandardDescriptionBlock;

import React, { useEffect, useState } from "react";
import "./ifyouare-section.scss";
import { Col, Container, Row } from "codeebo-react-grid";
import Dropdown from "../../../../../libs/dropdown/dropdown";

const combinations = [
  {
    personType: "recruiter",
    contactType: "hire me",
    body: (
      <>
        looking for
        <br />
        a designer for your company and
        <br />
        you like what you see,
      </>
    ),
  },
  {
    personType: "client",
    contactType: "contact me",
    body: (
      <>
        needing any
        <br />
        kind of interface, and you like
        <br />
        what you see
      </>
    ),
  },
  {
    personType: "designer",
    contactType: "let's talk",
    body: (
      <>
        who needs
        <br />
        help with a project or wants
        <br />
        to collaborate,
      </>
    ),
  },
];

const findCombination = (personalType: string, contactType: string) => {
  const combination = combinations.find((x) => x.contactType === contactType && x.personType === personalType);
  return combination ?? combinations[0];
};

const findPersonTypeByContactType = (contactType: string) => {
  const combination = combinations.find((x) => x.contactType === contactType);
  return combination?.personType;
};

const findContactTypeByPersonType = (personalType: string) => {
  const combination = combinations.find((x) => x.personType === personalType);
  return combination?.contactType;
};

const IfyouareSection: React.FC<any> = () => {
  const [personType, setPersonType] = useState("recruiter");
  const [contactType, setContactType] = useState("hire me");
  const [combination, setCombination] = useState(findCombination(personType, contactType));

  useEffect(() => {
    setCombination(findCombination(personType, contactType));
  }, [personType, contactType]);

  return (
    <section className="ifyouare-section my-md-5 my-4 py-md-5 py-4">
      <Container>
        <Row>
          <Col size={12}>
            <h2 data-aos="fade-in">
              If you are a{" "}
              <Dropdown
                id="first"
                onChange={(x) => {
                  setPersonType(x);
                  setContactType(findContactTypeByPersonType(x) ?? combination.contactType);
                }}
                options={["recruiter", "client", "designer"]}
                value={personType}
              />{" "}
              {combination.body}{" "}
              <Dropdown
                id="second"
                onChange={(x) => {
                  setContactType(x);
                  setPersonType(findPersonTypeByContactType(x) ?? combination.personType);
                }}
                options={["hire me", "contact me", "let's talk"]}
                value={contactType}
              />
            </h2>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IfyouareSection;

/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import StandardTile from "../../../../components/standardTile/standardTile";
import "./questions-section.scss";
import { Col, Container, Row } from "codeebo-react-grid";

import "./../../../../../swiperWrapper.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// import A11y from "swiper";
// import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Autoplay } from "swiper/modules";

const QuestionsSection: React.FC<any> = () => {
  const tiles = [
    <>
      <StandardTile className="mb-2" disableLargeRadius>
        <p>As it happens I don't have the cash, but I have a crypto project to do, shall we get along?</p>
      </StandardTile>
      <StandardTile className="isWhite">
        <p>Sure we'll get along. If you don't have money but need a site or visual identity for a project, you can pay with your token.</p>
      </StandardTile>
    </>,
    <>
      <StandardTile className="mb-2" disableLargeRadius>
        <p>Ok, you do the design and I still need a coder, now what?</p>
      </StandardTile>
      <StandardTile className="isWhite">
        <p>
          You made a good point - I don't code (yet). But I have colleagues who are code Mozarts. I also have Salieries, if you are short on cash. Anyway, I've
          got you - if you don't want to, you won't even meet them - you'll only be dealing with me.
        </p>
      </StandardTile>
    </>,
    <>
      <StandardTile className="mb-2" disableLargeRadius>
        <p>I need a visual identity before I can order a UI, do you deal with such things?</p>
      </StandardTile>
      <StandardTile className="isWhite">
        <p>
          Yes, I do identity and brand design, you can see my projects on this{" "}
          <b>
            <a href="https://www.spnm.pl" target="_blank">
              website
            </a>
          </b>
        </p>
      </StandardTile>
    </>,
    <>
      <StandardTile className="mb-2" disableLargeRadius>
        <p>Are you familiar with agile techniques and how do you feel about them?</p>
      </StandardTile>
      <StandardTile className="isWhite">
        <p>
          Yes, I'm familiar with sprint, scrum, iterations etc. I have gone through these approaches while working in IT and I can work in specified frameworks.
          I believe that with larger teams they are useful, but when I work alone, all I need is a piece of paper and a pen.
        </p>
      </StandardTile>
    </>,
    <>
      <StandardTile className="mb-2" disableLargeRadius>
        <p>Are you looking for longer or shorter projects and commitments?</p>
      </StandardTile>
      <StandardTile className="isWhite">
        <p>Both with an indication of the second one. After finishing my last project in IT, I'm looking for something similar, hoping to get into Web3.</p>
      </StandardTile>
    </>,
    <>
      <StandardTile className="mb-2" disableLargeRadius>
        <p>What form of contract would work for you?</p>
      </StandardTile>
      <StandardTile className="isWhite">
        <p>
          I have been running my own business for several years, so B2B is the preferred form, but I have absolutely nothing against other forms of billing.
        </p>
      </StandardTile>
    </>,
    <>
      <StandardTile className="mb-2" disableLargeRadius>
        <p>What do you bring to the teams as a member?</p>
      </StandardTile>
      <StandardTile className="isWhite">
        <p>
          Absolute sincerity and respect for time. These two things go together, because I don't like to waste time on unnecessary conversations and people who
          lack competence.
        </p>
      </StandardTile>
    </>,
  ];
  return (
    <section className="questions-section my-md-5 my-4 py-md-5 py-4" data-aos="fade-in">
      <Container>
        <Row>
          <Col size={12} className="pt-4">
            <h2 data-aos="fade-in">FAQ</h2>
          </Col>
        </Row>

        <Row className="py-4" gap={8}>
          <Col size={12} className="swiperWrapper">
            <Swiper
              modules={[Autoplay, Scrollbar]}
              spaceBetween={16}
              // slidesPerView={4}

              breakpoints={{
                0: { slidesPerView: 1 },
                700: { slidesPerView: 2 },
                1200: { slidesPerView: 3 },
                1400: { slidesPerView: 4 },
              }}
              loop={false}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              scrollbar={{
                draggable: true,
              }}
              // pagination={{
              //   clickable: true,
              //   renderBullet: function (index, className) {
              //     return '<span class="' + className + '">' + (index + 1) + "</span>";
              //   },
              // }}
              // onSwiper={(swiper) => console.log(swiper)}
              // onSlideChange={() => console.log("slide change")}
            >
              {tiles.map((tile: React.ReactElement, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <div key={index} className="d-flex d-flex-col h-auto mb-3">
                      {React.cloneElement(tile, { attributes: { "data-aos": "reveal-tile", "data-aos-delay": `${500}` } })}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default QuestionsSection;

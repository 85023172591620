import React from "react";
import "./cloud.scss";

interface CloudProps {
  color: string;
  delay?: number;
  offsetTop?: number;
}

const Cloud: React.FC<CloudProps> = ({ color = "8FFF5C", delay = 0, offsetTop = 0 }) => {
  return (
    // <div style={{ transform: `translateY(${offsetTop}px)` }}>
      <div className="cloud-container-wrapper">
        <div className="cloud-container">
          <div className="cloud" style={{ animationDelay: `${-delay}s` }}>
            <svg width="585" height="543" viewBox="0 0 585 543" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M302.272 33.7297C341.566 41.639 381.897 42.1277 417.3 60.9212C453.465 80.1187 490.777 104.426 506.594 142.191C522.067 179.135 490.386 221.468 500.746 260.157C516.548 319.165 601.122 363.478 581.326 421.269C563.319 473.84 477.819 453.803 426.84 475.916C382.925 494.965 350.055 545.17 302.272 542.313C254.348 539.448 230.631 479.249 186.015 461.519C130.374 439.407 47.9765 475.735 12.8053 427.281C-20.9191 380.819 20.4491 314.189 39.8559 260.157C54.923 218.208 93.4279 190.851 112.239 150.442C133.535 104.694 113.993 34.9347 156.992 8.52598C199.6 -17.6421 253.253 23.8632 302.272 33.7297Z"
                fill={color}
              />
            </svg>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default Cloud;

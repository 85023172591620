import React from 'react';

export const CookiePolicy = () => {
  return (
    <div className="policy">
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic reprehenderit deleniti rem, soluta laudantium quas dicta? Neque, cum est at amet consectetur enim quidem voluptas veritatis quo tenetur omnis recusandae! Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur hic, perspiciatis, tempora quo cum alias dolorem architecto sapiente officia minus necessitatibus repellendus, provident corporis sunt nobis sint iure voluptatum? Voluptatibus.</p>
      <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit ducimus eum repellendus quidem! Quas numquam tenetur vel, maiores et neque unde illo natus voluptate nesciunt magnam repellendus deleniti consequuntur veniam.</p>
      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laudantium architecto quis, necessitatibus nisi eaque, harum tenetur earum omnis quod labore, fugit ullam officia culpa saepe rerum magnam laboriosam eos voluptates!</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic reprehenderit deleniti rem, soluta laudantium quas dicta? Neque, cum est at amet consectetur enim quidem voluptas veritatis quo tenetur omnis recusandae! Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur hic, perspiciatis, tempora quo cum alias dolorem architecto sapiente officia minus necessitatibus repellendus, provident corporis sunt nobis sint iure voluptatum? Voluptatibus.</p>
      <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit ducimus eum repellendus quidem! Quas numquam tenetur vel, maiores et neque unde illo natus voluptate nesciunt magnam repellendus deleniti consequuntur veniam.</p>
      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laudantium architecto quis, necessitatibus nisi eaque, harum tenetur earum omnis quod labore, fugit ullam officia culpa saepe rerum magnam laboriosam eos voluptates!</p>
    </div>
  )
}
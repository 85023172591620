export class RoutePath {
  static RULES: string = "/warunki-uzytkowania";
  static HOME: string = "/";
  static PORTFOLIOS: string = "/projects";

  static PORTFOLIO = {
    path: "/projects/:id",
    generator: (id: string) => `/projects/${id}`,
  };
}

import "./description-section.scss";
import { Col, Container, Row } from "codeebo-react-grid";
import { PortfolioData, PortfolioItemDto } from "../../../../_data/PortfolioData";
import Button from "../../../../../libs/buttons/Button/Button";
import TopLink from "../../../../../libs/top-link/top-link";
import { RoutePath } from "../../../../../route-paths";

function findNextIndex(currentIndex: number) {
  if (currentIndex < PortfolioData.length - 1) {
    if (PortfolioData[currentIndex + 1]?.disabled !== true) return currentIndex + 1;
    else return findNextIndex(currentIndex + 1);
  } else return findNextIndex(-1);
}
const DescriptionSection = ({ portfolioItem }: { portfolioItem: PortfolioItemDto }) => {
  const itemIndex = PortfolioData.findIndex((x) => x.id === portfolioItem.id);
  let nextItemIndex = findNextIndex(itemIndex);
  const nextItem = PortfolioData[nextItemIndex];

  return (
    <section className="description-section pb-md-5 pb-4 mb-3" data-aos="fade-in">
      <Container className="pb-5">
        <Row>
          <Col size={[5, 6, 6, 12]}>
            <div className="pr-md-4 pr-0">{portfolioItem.description(portfolioItem.primaryColor, portfolioItem.fontsColor)}</div>
          </Col>
          <Col size={[5, 6, 6, 12]} offset={[2, 0]} className="buttons-wrapper-col">
            <div className="buttons-wrapper">
              <TopLink to="/">
                <Button variant="primary-icon" style={{ transform: "scaleX(-1)" }}>
                  <span style={{ transform: "scaleX(-1)" }}>Back&nbsp;to&nbsp;main</span>{" "}
                  <img className="ml-2 my-auto" src="/images/system-icons/right-arrow-icon.svg" alt="->" />
                </Button>
              </TopLink>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <TopLink to={RoutePath.PORTFOLIO.generator(nextItem.id)}>
                <Button variant="primary-icon">
                  Next&nbsp;project <img className="ml-2 my-auto" src="/images/system-icons/right-arrow-icon.svg" alt="->" />
                </Button>
              </TopLink>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DescriptionSection;

import "./portfolio-page.scss";

import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import HeroSection from "./sections/hero-section/hero-section";
import { PortfolioData } from "../../_data/PortfolioData";
import { Container } from "codeebo-react-grid";
import { RoutePath } from "../../../route-paths";
import GallerySection from "./sections/gallery-section/gallery-section";
import DescriptionSection from "./sections/description-section/description-section";

const PortfolioPage = () => {
  const { id } = useParams();
  const portfolioItem = PortfolioData.find((x) => x.id === id && x.disabled !== true);

  return (
    <div className="portfolio-page">
      {portfolioItem ? (
        <>
          <Helmet>
            <title>{portfolioItem.title + " | Stuxpid"}</title>
          </Helmet>
          <HeroSection portfolioItem={portfolioItem} />
          <GallerySection portfolioItem={portfolioItem} />
          <DescriptionSection portfolioItem={portfolioItem} />
        </>
      ) : (
        <Container className="p-5">
          <div className="p-5">
            <h1 className="pb-4" style={{ fontSize: "4rem" }}>
              Url not found...
            </h1>
            <a href={RoutePath.HOME} style={{ textDecoration: "underline", fontSize: "2rem" }}>
              Back to HOME
            </a>
          </div>
        </Container>
      )}
    </div>
  );
};

export default PortfolioPage;

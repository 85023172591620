import React from "react";
import StandardTile from "../../../../components/standardTile/standardTile";
import "./process-section.scss";
import { Col, Container, Row } from "codeebo-react-grid";


const ProcessSection: React.FC<any> = () => {
  const tilesFirstPPaddingBottom = "pb-md-4 pb-3";
  const tiles = [
    <StandardTile>
      <p className={tilesFirstPPaddingBottom}>
        <b>1.</b>
      </p>
      <p>We get to know each other and see if there is a business fit.</p>
    </StandardTile>,
    <StandardTile>
      <p className={tilesFirstPPaddingBottom}>
        <b>2.</b>
      </p>
      <p>You tell me what you need and I ask some questions if I need to. </p>
    </StandardTile>,
    <StandardTile className="always-hover">
      <p className={tilesFirstPPaddingBottom}>
        <b>3.</b>
      </p>
      <p>If we don't know each other, I take an advance.</p>
    </StandardTile>,
    <StandardTile>
      <p className={tilesFirstPPaddingBottom}>
        <b>4.</b>
      </p>
      <p>I deliver the design. If necessary, we make some adjustments.</p>
    </StandardTile>,
    <StandardTile>
      <p className={tilesFirstPPaddingBottom}>
        <b>5.</b>
      </p>
      <p>You pay me with money, or crypto if that suits you better.</p>
    </StandardTile>,
    <StandardTile>
      <p className={tilesFirstPPaddingBottom}>
        <b>6.</b>
      </p>
      <p>I do a hand off of the project and we part ways in friendship.</p>
    </StandardTile>,
  ];
  return (
    <section className="process-section mt-md-5 mt-4 pt-md-5 pt-4" data-aos="fade-in" id="process">
      <Container>
        <Row>
          <Col size={[5, 5, 6, 12]}>
            <h2 data-aos="fade-in" className="pt-3">Process</h2>
            <h3 data-aos="fade-in" className="pt-3">If you become a client, our cooperation will proceed as follows</h3>
          </Col>
          <Col size={[5, 6, 6, 12]} offset={[1, 1, 0]}>
            <div className="pl-lg-4 pl-md-0 pl-0">
              <Row className="py-4" gap={8}>
                {tiles.map((tile: React.ReactElement, index: number) => {
                  return (
                    <Col size={[6, 6, 6, 12]} key={index} className="d-flex h-auto mb-3">
                      {React.cloneElement(tile, {
                        attributes: { "data-aos": "reveal-tile", "data-aos-delay": `${100 + (index%2) * 300}` },
                        hoverable: true,
                        disableLargeRadius: tiles.length !== index + 1,
                      })}
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ProcessSection;

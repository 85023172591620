// import React, { useEffect, useRef, useState } from "react";
import StandardTile from "../../../../components/standardTile/standardTile";
import "./toolstack-section.scss";
import { Col, Container, Row } from "codeebo-react-grid";

interface ToolDto {
  name: string;
  img: string;
  color: "primary" | "white";
}

const ToolstackSection: React.FC<any> = () => {
  const toolsStack: ToolDto[] = [
    { name: "Figma", img: "/images/icons/logo-figma.svg", color: "primary" },
    { name: "Adobe XD", img: "/images/icons/adobe-xd.svg", color: "primary" },
    { name: "Adobe Illustrator", img: "/images/icons/adobe-illustrator.svg", color: "primary" },
    { name: "Adobe InDesign", img: "/images/icons/adobe-indesign.svg", color: "primary" },
    { name: "Adobe Photoshop", img: "/images/icons/adobe-photoshop.svg", color: "primary" },
    { name: "Midjourney", img: "/images/icons/midjourney-logo.svg", color: "white" },
    { name: "Loom", img: "/images/icons/logo-loom.svg", color: "white" },
    { name: "Miro", img: "/images/icons/logo-miro.svg", color: "white" },
    { name: "Monday", img: "/images/icons/logo-monday.svg", color: "white" },
    { name: "Slack", img: "/images/icons/logo-slack.svg", color: "white" },
    { name: "Notion", img: "/images/icons/logo-notion.svg", color: "white" },
    { name: "Pitch", img: "/images/icons/logo-pitch.svg", color: "white" },
  ];

  // const tileContainerRef = useRef<any>(null);

  // const checkIfInSecondRow = (tileIndex: number, recheckTrigger: number) => {
  //   const tileContainer = tileContainerRef.current;
  //   if (tileContainer && tileContainer.children) {
  //     const tileElements: any[] = Array.from(tileContainer.children);
  //     const containerTop = tileContainer.offsetTop;
  //     const containerHeight = tileContainer.offsetHeight;

  //     const tileTop = tileElements[tileIndex].offsetTop;
  //     const isBelowContainerTop = tileTop - containerTop > 84;
  //     const isSecondRow = isBelowContainerTop && tileTop - containerTop < containerHeight;
  //     return isSecondRow;
  //   } else return false;
  // };

  // const [recheckTrigger, setRecheckTrigger] = useState<number>(0);

  // useEffect(() => {
  //   window.addEventListener('resize', ()=>setRecheckTrigger(x=>x++));
  //   setRecheckTrigger(x=>x++)

  //   return () => {
  //     window.removeEventListener('resize', ()=>setRecheckTrigger(x=>x++));
  //   };
  // }, [toolsStack]);
  // useEffect(() => {
  //   setRecheckTrigger((x) => x + 1);
  //   setInterval(() => {
  //     setRecheckTrigger((x) => x + 1);
  //   }, 500);
  // }, []);

  return (
    <section className="toolstack-section my-md-5 my-4 py-md-5 py-4" data-aos="fade-in">
      <Container>
        <Row>
          <Col size={12} className="pt-4">
            <h2 data-aos="fade-in">Toolstack</h2>
          </Col>
        </Row>

        <Row className="py-4" gap={8}>
          <Col size={12}>
            <div className="d-flex toolstack-tiles">
              {toolsStack.map((tool: ToolDto, index: number) => {
                const isWhite = tool.color === "white";
                return (
                  <StandardTile
                    index={index + 3}
                    className={`mb-2 mr-2 d-flex center-y ${isWhite ? "isWhite" : ""}`}
                    key={index}
                    miniPadding
                    disableLargeRadius
                  >
                    <img src={tool.img} alt={tool.name} />
                    <h3 className="px-sm-2 px-1 my-auto">{tool.name}</h3>
                  </StandardTile>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ToolstackSection;

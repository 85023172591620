import React, { useEffect, useState, useRef } from "react";

const ScrollDebugLine = ({ scrollProgress, scrollReference = "center" }: { scrollProgress: number; scrollReference: "top" | "center" | "bottom" }) => {
  const calcTopPosition = (scrollReference: "top" | "center" | "bottom") => {
    if (scrollReference === "bottom") return 100;
    else if (scrollReference === "top") return 0;
    else return 50;
  };
  return (
    <div
      style={{
        position: "fixed",
        top: `${calcTopPosition(scrollReference)}vh`,
        left: 0,
        width: "100%",
        height: "2px",
        background: "red",
        zIndex: 9999,
      }}
    >
      <span style={{ position: "absolute", top: "100%", left: "5px" }}>{scrollProgress.toFixed(2)}%</span>
    </div>
  );
};

interface ScrollWrapperProps {
  children: React.ReactNode;
  onScrollProgress: (progress: number) => void;
  debug?: boolean;
  scrollReference?: "top" | "center" | "bottom";
}

const ScrollWrapper: React.FC<ScrollWrapperProps> = ({ children, onScrollProgress, debug = false, scrollReference = "top" }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const wrapperTop = wrapper.getBoundingClientRect().top;
    const wrapperHeight = wrapper.offsetHeight;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    const scrollTop = windowHeight / 2; // Środek ekranu (50vh)

    let progress = 0;

    if (wrapperTop <= scrollTop) {
      // Przesunięcie środka ekranu poniżej górnej krawędzi wrappera
      progress = ((scrollTop - wrapperTop) / wrapperHeight) * 100;
      progress = Math.max(progress, 0); // Minimalna wartość postępu to 0
      progress = Math.min(progress, 100); // Maksymalna wartość postępu to 100
    }

    setScrollProgress(progress);
    onScrollProgress(progress);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {debug && <ScrollDebugLine scrollProgress={scrollProgress} scrollReference={scrollReference} />}
      <div ref={wrapperRef} style={debug ? { border: "2px solid lightgreen" } : {}}>
        {children}
      </div>
    </>
  );
};

export default ScrollWrapper;

import React from "react";
import StandardTile from "../../../../components/standardTile/standardTile";
import "./approach-section.scss";
import { Col, Container, Row } from "codeebo-react-grid";

const ApproachSection: React.FC<any> = () => {
  const tiles = [
    <StandardTile>
      <h3 className="pb-md-5 pb-4">
        Time is money, chit-chat is not money <img src="/images/system-icons/doomIcon.png" alt="icon"/>
      </h3>
      <p className="mt-auto">
        This means absolutely no workshops. I've been doing this long enough that I'll know what you need after a short brief and few easy questions.
      </p>
    </StandardTile>,
    <StandardTile>
      <h3 className="pb-md-5 pb-4">Fast pace, top quality, good price</h3>
      <p className="mt-auto">
        Work is not my passion, so I try to spend as little time on it as possible. That's why I've become pretty efficient over the years.{" "}
      </p>
    </StandardTile>,
    <StandardTile>
      <h3 className="pb-md-5 pb-4">You Talkin' To Me? You Talkin' To Me?</h3>
      <p className="mt-auto">
        We'll do without the newspeak. If you've ever felt bad in a meeting because you didn't cuss the programming jibber jabber, no worries here, I got you.
      </p>
    </StandardTile>,
    <StandardTile>
      <h3 className="pb-md-5 pb-4">Holey moley holistic</h3>
      <p className="mt-auto">
        A good interface, is a product of a strong and consistent visual identity, not the other way around. I can do both, and if necessary I will even write
        copy.
      </p>
    </StandardTile>,
  ];
  return (
    <section className="approach-section my-md-5 my-4 py-md-5 py-4" data-aos="fade-in" id="approach">
      <Container>
        <Row>
          <Col size={12} className="pt-4">
            <h2 data-aos="fade-in">Approach</h2>
          </Col>
        </Row>

        <Row className="py-4" gap={8}>
          {tiles.map((tile: React.ReactElement, index: number) => {
            return (
              <Col size={[3, 4, 6, 12]} key={index} className="d-flex h-auto mb-3">
                {React.cloneElement(tile, { hoverable: true, attributes: { "data-aos": "reveal-tile", "data-aos-delay": `${100 + index * 300}` } })}
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default ApproachSection;

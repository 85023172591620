import React, { useEffect, useState } from "react";
import "./dropdown.scss";
import { Collapse } from "react-collapse";

interface DropdownProps {
  onChange: (selectedOption: any) => void;
  options: Array<string>;
  value?: string;
  id: string;
}

const Dropdown = (props: DropdownProps) => {
  const { options, onChange, value, id } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  // const options = ["recruiter", "client", "designer"];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (index: any) => {
    setSelectedOption(index);
    onChange(options[index]);
    setIsOpen(false);
  };

  const handleOutsideClick = (event: any) => {
    if (!event.target.closest(`.badge-dropdown-${id}`)) {
      setIsOpen(false);
    }
  };

  document.addEventListener("click", handleOutsideClick);

  const polygon = () => {
    return (
      <svg className="polygon" xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none">
        <path d="M8 8.5L-6.99382e-07 0.500001L16 0.5L8 8.5Z" fill="#8FFF5C" />
      </svg>
    );
  };

  useEffect(() => {
    var valueIndex = options.findIndex((x) => x === value);
    if (valueIndex !== -1 && value !== options[selectedOption]) setSelectedOption(valueIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div style={{ position: "relative", display: "inline-flex" }} className={`badge-dropdown-${id} badge-dropdown ${isOpen ? "--isOpen" : ""}`}>
      <span className={`badge-dropdown-toggle ${isOpen ? "open" : ""}`} onClick={toggleDropdown}>
        {options[selectedOption]}&nbsp;{polygon()}
      </span>

      <div className="badge-dropdown-menu">
        <Collapse isOpened={isOpen}>
          {options.map((option, index) => (
            <div
              key={index}
              className={`badge-dropdown-menu-item ${index === 0 ? "first-child" : index === options.length - 1 ? "last-child" : ""} ${
                selectedOption === index ? "active" : ""
              }`}
              onClick={() => handleOptionSelect(index)}
            >
              {option}&nbsp;{polygon()}
            </div>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default Dropdown;

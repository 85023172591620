import "aos/dist/aos.css";

// import { useLocation } from "react-router-dom";
import AOS from "aos";
import { useEffect } from "react";

function AosInit({ observedData }: any) {
  // const location = useLocation();

  const initAos = () => {
    // setTimeout(() => {
    var isSafari = (window as any).safari !== undefined;
    AOS.init({
      duration: 2000,
      once: true,
      disable: function () {
        // return true;
        var maxWidth = 900;
        return window.innerWidth < maxWidth || isSafari;
      },
    });
    // }, 500);
  };

  useEffect(() => {
    initAos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (observedData) initAos();
  }, [observedData]);

  return <></>;
}

export default AosInit;

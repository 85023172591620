import React, { useRef, useEffect } from "react";
import './rules-page.scss'
import { PrivacyPolicy } from './privacy-policy';
import { CookiePolicy } from './cookie-policy';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { Container } from "codeebo-react-grid";

export const RulesPage: React.FC<any> = (props: any) => {

  const rulesLink = useRef<any>()
  const privacyLink = useRef<any>()

  const { state } = useLocation();

  useEffect(() => {
    if (state && (state as any).scrollTo && (state as any).scrollTo === 'top') {
      window.scrollTo(0, 0);
      window.history.pushState(null, '')
      // window.scrollTo(0, privacyLink.current!.getBoundingClientRect().top + window.pageYOffset);
    }
    else if (state && (state as any).scrollTo && (state as any).scrollTo === 'rules') {
      window.scrollTo(0, rulesLink.current!.getBoundingClientRect().top + window.pageYOffset);
      window.history.pushState(null, '')
    }
    else {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <>
      <Helmet>
        <title>{'Stuxpid'}</title>
      </Helmet>
      <Container>
        <section className="pageWrapper__section">
          POLITYKA STRONY
          <h3 ref={rulesLink}>
            Polityka Cookies
          </h3>
          <CookiePolicy />
          <h3 ref={privacyLink}>
            Polityka Prywatności
          </h3>
          <PrivacyPolicy />
        </section>
      </Container>
    </>
  )
}
import "./standardTile.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  attributes?: any;
  disableLargeRadius?: boolean;
  miniPadding?: boolean;
  hoverable?: boolean;
  index?: number;
}

const StandardTile = (props: Props) => {
  const { attributes = {}, index, disableLargeRadius, miniPadding, className = "", children, hoverable } = props;
  return (
    <div
      {...(index ? { "data-aos": "fade-in", "data-aos-delay": 100 * (index ?? 0) } : {})}
      {...attributes}
      className={`standardTile ${disableLargeRadius ? "disableLargeRadius" : ""} ${hoverable ? "hoverable" : ""} ${
        miniPadding ? "miniPadding" : ""
      } ${className}`}
    >
      {children}
    </div>
  );
};

export default StandardTile;

import "./App.scss";

import { RoutePath } from "../route-paths";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { RulesPage } from "./pages/rules-page/rules-page";
import RouterTransition from "../libs/router-transition/router-transition";
import Footer from "./components/footer/footer";
import Navbar from "./components/navbar/navbar";
import { useNavbar } from "./components/navbar/navbarSlice";
import HomePage from "./pages/home-page/home-page";
import useTheme from "../tools/theme-controller/useTheme";
import AosInit from "../tools/aos-init/aos-init";
import PortfolioPage from "./pages/portfolio-page/portfolio-page";
import Cloud from "./components/cloud/cloud";

function App() {
  const { theme } = useTheme();
  const { isOpen } = useNavbar();
  const { pathname } = useLocation();

  return (
    <>
      <div id="app" className={`theme-${theme}`}>
        {pathname === "/" ? <Cloud color={"#8FFF5C"} delay={5} /> : null}
        <RouterTransition type="home" />
        <Navbar />
        <div className={`overlay${isOpen ? " isActive" : ""}`}>
          <div className="routes-wrapper">
            <Routes>
              <Route path={RoutePath.RULES} element={<RulesPage />} />
              <Route path={RoutePath.PORTFOLIO.path} element={<PortfolioPage />} />
              <Route path={RoutePath.PORTFOLIOS} element={<Navigate to={RoutePath.HOME} />} />
              <Route path={RoutePath.HOME} element={<HomePage />} />
              {/* <Route path={"*"} element={<NotFoundPage />} /> */}
            </Routes>
          </div>
          <Footer />
        </div>
        {/* <CookiePolicyPopup /> */}
      </div>
      {/* <div className="app-in-progress">
        <div className="cloud-wrapper">
          <Cloud color={"#8FFF5C"} delay={25} offsetTop={100} />
        </div>
        <Container>
          <div className="title">MOBILE VERSION COMING SOON</div>
        </Container>
      </div> */}
      <AosInit />
      {/* <FadeInEffectGlobal activatorClass="fade-in-global"/> */}
    </>
  );
}

export default App;
